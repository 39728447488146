import React, { Component } from 'react'
import ProjectBox from './ProjectBox'
import './ProjectPage.css'
import data from './data.json';

export class ProjectsPage extends Component {
    render() {
        return (
            <>
                <div className="projects-body">
                    <div className="content">

                        <br />
                        <h2>Projects</h2>
                        <br />

                        <div className='projects-grid'>

                            {
                                data.projects.map((project) => {

                                    if (!project.show) {
                                        return null;
                                    }

                                    return (
                                        <ProjectBox key={project.name} project={project} />
                                    )
                                })
                            }

                        </div>

                        <br />
                        <br />

                    </div>
                </div>

            </>
        )
    }
}

export default ProjectsPage